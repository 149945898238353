<template>
  <div :class="propsClassName">
    <div class="form-check" v-for="(option, inx) in options" :key="inx" :class="{'selected': option.id===selected}">

      <input class="form-check-input" type="checkbox" name="flexCheckboxDefault"
      :id="`flexCheck${inx}`" @click="()=>chooseThis(option.id)"
      :checked="option.id===selected">

      <label class="form-check-label" :for="`flexCheck${inx}`">
        {{ option.label }}
      </label>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'select-sub-category',
  props: {
    propsClassName: {
      type: String,
      default: '',
    },
    options: {
      type: Array,
      default: () => [],
    },
    category: {
      type: Object,
      default: null,
    },
  },
  computed: {
    ...mapGetters({
      selected: 'GET_FH_SELECTED_SUB_CATE',
    }),
  },
  data() {
    return {
      query: this.$route.query,
    };
  },
  mounted() {
  },
  methods: {
    chooseThis(subId) {
      this.$store.commit('SET_FH_SELECTED_SUB_CATE', subId);
    },
  },
};
</script>
